<template>
    <div class="container">
        <!-- 搜索框 -->
        <van-search shape="round" v-model="searchValue" placeholder="输入关键词进行搜索" @search="onSearch" show-action clear-icon=""
            left-icon="">
            <template #label>
                <img class="searchicon"
                    src="https://meiyun-mng.oss-cn-hangzhou.aliyuncs.com/upload/bigscreen/img/%E6%AD%A3%E5%BC%8F%E5%BA%93/%E6%96%B0%E6%A0%87%E5%87%86%E6%96%87%E6%97%85/%E6%90%9C%E7%B4%A201%402x.png" />
            </template>
            <template #action>
                <div class="actiontext" @click="onCancel">取消</div>
            </template>
        </van-search>
        <!-- 历史搜索 -->
        <div v-if="!searchValue">
            <div class="historybox">
                <span>历史搜索</span>
                <img v-if="historylist.length > 0" @click="deleteAll"
                    src="https://meiyun-mng.oss-cn-hangzhou.aliyuncs.com/upload/bigscreen/img/%E6%AD%A3%E5%BC%8F%E5%BA%93/%E6%96%B0%E6%A0%87%E5%87%86%E6%96%87%E6%97%85/%E5%9E%83%E5%9C%BE%E6%A1%B6.png" />
            </div>
            <div class="historylist">
                <div @click="historyClick(item)" class="historyitem" v-for="(item, index) in historylist" :key="index">
                    {{ item.keyword }}
                </div>
            </div>
        </div>
        <!-- 搜索结果列表 -->
        <div class="searchbox" v-if="searchList.length > 0 && searchValue">
            <div class="searchitem" v-for="(item, index) in searchList" :key="index" @click="detailClick(item)">
                <img
                    src="https://meiyun-mng.oss-cn-hangzhou.aliyuncs.com/upload/bigscreen/img/%E6%AD%A3%E5%BC%8F%E5%BA%93/%E6%96%B0%E6%A0%87%E5%87%86%E6%96%87%E6%97%85/%E6%90%9C%E7%B4%A201%402x.png" />
                <div v-html="item.title" class="searvalue"></div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
import { Notify, Slider, Icon, Search, Dialog } from 'vant';
export default {
    components: {
        [Slider.name]: Slider,
        [Icon.name]: Icon,
        [Search.name]: Search,
        [Dialog.name]: Dialog,
    },
    data() {
        const latitude = this.$route.query.latitude || null;
        const longitude = this.$route.query.longitude || null;
        const lat = this.$route.query.userlat || 0;
        const lon = this.$route.query.userlon || 0;
        return {
            latitude,
            longitude,
            lat,
            lon,
            searchValue: '',
            historylist: [
            ],
            searchList: [
            ],
        };
    },
    mounted() {
        this.getSearchHistory()
    },
    methods: {
        historyClick(item) {
            this.searchValue = item.keyword
            this.onSearch()
        },
        onSearch() {
            const this_ = this;
            if (this.searchValue != '') {
                this_.$api
                    .searchDetails({
                        title: this.searchValue,
                        tenantId: 331122001210,
                    })
                    .then((res) => {
                        this.searchList = res.data.data.records.map(item => {
                            item.title = item.title.replaceAll(this.searchValue, "<span style='color:#FF6010'>$&</span>")
                            return item
                        });
                    });
            }
        },
        detailClick(item) {
            let topicSymbolIndex = '';
            switch (item.topicSymbol) {
                case 'min-attractions-detail':
                    topicSymbolIndex = 1
                    break;
                case 'min-park-lot-list':
                    topicSymbolIndex = 2
                    break;
                case 'min-toilet-list':
                    topicSymbolIndex = 3
                    break;
                case 'min-gasStation-detail':
                    topicSymbolIndex = 12
                    break;
                case 'min-catering-detail':
                    topicSymbolIndex = 4
                    break;
                case 'min-food-detail':
                    topicSymbolIndex = 4
                    break;
                case 'min-hotel-detail':
                    topicSymbolIndex = 5
                    break;
                case 'min-shop-detail':
                    topicSymbolIndex = 6
                    break;
                case 'min-entertainment-detail':
                    topicSymbolIndex = 11
                    break;
            }
            this.$router.push({ name: 'zhoucunMap', query: { id: item.id, topicSymbolIndex, biaotoken: window.localStorage.getItem('biaotoken'), latitude: this.latitude, longitude: this.longitude, userlat: this.lat, userlon: this.lon } })
        },
        // 获取搜索历史记录
        getSearchHistory() {
            this.$api
                .searchHistory({
                    type: 1,
                    tenantId: 331122001210,
                })
                .then((res) => {
                    this.historylist = res.data.data
                });
        },
        onCancel() {
            if (this.searchValue) {
                this.searchValue = ''
                this.getSearchHistory()
            }
            else {
                this.$router.push({ name: 'zhoucunMap', query: { biaotoken: window.localStorage.getItem('biaotoken') } })
            }
        },
        deleteAll() {
            Dialog.confirm({
                title: '提示',
                message: '确定要删除历史记录吗？',
            })
                .then(() => {
                    if (!this.historylist.length) return;
                    this.$api
                        .deleteHistory({
                            ids: this.historylist.map(item => item.id).join(',') || '',
                            tenantId: 331122001210,
                        })
                        .then((res) => {
                            if (res.data.code == 200) {
                                this.historylist = []
                                this.searchList = []
                                Notify({ type: 'success', message: '删除成功' });
                            }
                        });
                    // on confirm
                })
                .catch(() => {
                    // on cancel
                });
        },
    },
};
</script>
  
<style lang="scss" scoped>
$px: 1rem/37.5;

.searchbox {
    padding: 10 * $px;

    .searchitem {
        display: flex;
        padding: 10* $px 0;
        align-items: center;

        img {
            width: 16* $px;
            height: 16* $px;
        }

        border-bottom: 1px solid #eeeeee;
        font-size: 14 * $px;
        font-family: PingFangSC-Regular,
        PingFang SC;
        font-weight: 400;
        color: #333333;

        .searvalue {
            margin-left: 5* $px;
        }
    }
}

.actiontext {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
}

.historybox {
    display: flex;
    justify-content: space-between;
    padding: 5 * $px 10 * $px;

    span {
        font-size: 14 * $px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
    }

    img {
        width: 13 * $px;
        height: 13 * $px;
    }
}

.historylist {
    display: flex;
    flex-wrap: wrap;

    .historyitem {
        padding: 6 * $px;
        background: #eeeeee;
        border-radius: 40 * $px;
        font-size: 14 * $px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        margin-left: 8 * $px;
        margin-bottom: 8 * $px;
    }
}

.searchicon {
    width: 16 * $px;
    height: 16 * $px;
    margin-top: 10 * $px;
}

.container {
    padding: 12 * $px;
}
</style>