/*
 * @Author: jinqingquan 2842427165@qq.com
 * @Date: 2023-06-25 18:09:23
 * @LastEditors: jinqingquan 2842427165@qq.com
 * @LastEditTime: 2024-02-04 13:59:51
 * @FilePath: \xjd_h5\src\api\api.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */

// 引入axios
import axios from 'axios';

// 设置请求头可携带cookie
// axios.defaults.withCredentials = true;
// axios.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
// axios.defaults.headers['Accept'] = 'application/json;charset=UTF-8';
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

// 测试服务
// var base = '';
// 生产地址
// var base = 'https://wx.jiedadt.com';

// export const baseUrl = "https://wx.jiedadt.com";
//  export const base = "https://mctest.beadata.cn";
export const base = 'https://travelh5.beadata.cn';

// export const base = "https://wanxingtravel.beadata.cn"

// 分页获取某栏目内容
export const getContentByPage = (params) => {
  axios.defaults.headers['Tenant-Id'] = params.tenantId;
  let path = `/meiyun-mct-cms/api/content/page`;
  console.log('params', params);
  switch (parseInt(params.tenantId)) {
    case 330481101204:
      path = '/meiyun-mct-cms-yongfu/api/content/page';
      break;
    case 330481106227:
      path = '/meiyun-mct-cms-haichao/api/content/page';
      break;
    case 331125105201:
      path = '/meiyun-mct-cms-meiyuan/api/content/page';
      break;
    case 330481101000:
      path = '/meiyun-mct-cms-ketong/api/content/page';
      break;
    case 330481107209:
      path = '/meiyun-mct-cms-wanxing/api/content/page';
      break;
    case 331122001210:
        path = '/meiyun-mct-cms-zhoucun/api/content/page';
        break;
    default:
      break;
  }
  console.log('path', path);
  return axios.get(path, { params: params }).then((res) => res);
};

// 获取单内容栏目下的内容
export const getContentDetail = (params) => {
  axios.defaults.headers['Tenant-Id'] = params.tenantId;
  let path = `/meiyun-mct-cms/api/content/get`;
  if (params.tenantId && params.tenantId == 330481106227) {
    path = '/meiyun-mct-cms-haichao/api/content/get';
  }else if(params.tenantId && params.tenantId == 330481101000){
    path = '/meiyun-mct-cms-ketong/api/content/get';
  }else if(params.tenantId && params.tenantId == 330481107209){
    path = '/meiyun-mct-cms-wanxing/api/content/get';
  }else if(params.tenantId && params.tenantId == 331122001210){
    path = '/meiyun-mct-cms-zhoucun/api/content/get';
  }
  return axios.get(path, { params: params }).then((res) => res);
};

// 通过行政吗获取中心点
export const getCenterByCityCode = (params) => {
  return axios
    .get('https://restapi.amap.com/v3/config/district', { params: params })
    .then((res) => res);
};
// 搜索
export const searchDetails = (params) => {
  axios.defaults.headers['Blade-Auth'] = window.localStorage.getItem('biaotoken')
  let path = ''
  if(params.tenantId && params.tenantId == 331125105201){
    path = '/meiyun-mct-cms-meiyuan/api/content/conditionQuery'
  }else if(params.tenantId && params.tenantId == 330481101000){
    path = '/meiyun-mct-cms-ketong/api/content/conditionQuery'
  }else if(params.tenantId && params.tenantId == 330481107209){
    path = '/meiyun-mct-cms-wanxing/api/content/conditionQuery'
  }else if(params.tenantId && params.tenantId == 331122001210){
    path = '/meiyun-mct-cms-zhoucun/api/content/conditionQuery'
  }
  console.log('000', axios.defaults.headers['Blade-Auth']);
  // uni.showToast({  title: "1111:" + axios.defaults.headers['Blade-Auth']});
  return axios.get(path, { params: params }).then((res) => res);
};
// 搜索历史
export const searchHistory = (params) => {
  axios.defaults.headers['Blade-Auth'] = window.localStorage.getItem('biaotoken')
  let path = ''
  if(params.tenantId && params.tenantId == 331125105201){
    path = '/meiyun-mct-cms-meiyuan/search-history/list'
  }else if(params.tenantId && params.tenantId == 330481101000){
    path = '/meiyun-mct-cms-ketong/search-history/list'
  } else if (params.tenantId && params.tenantId == 330481107209) {
    path = '/meiyun-mct-cms-wanxing/search-history/list'
  } else if (params.tenantId && params.tenantId == 331122001210) {
    path = '/meiyun-mct-cms-zhoucun/search-history/list'
  }
  return axios.get(path, { params: params }).then((res) => res);
};
// 删除搜索历史
export const deleteHistory = (params) => {
  let path = ''
  if(params.tenantId && params.tenantId == 331125105201){
    path = `/meiyun-mct-cms-meiyuan/search-history/delete?ids=${params.ids}`
  }else if(params.tenantId && params.tenantId == 330481101000){
    path = `/meiyun-mct-cms-ketong/search-history/delete?ids=${params.ids}`
  }else if(params.tenantId && params.tenantId == 330481107209){
    path = `/meiyun-mct-cms-wanxing/search-history/delete?ids=${params.ids}`
  } else if(params.tenantId && params.tenantId == 331122001210){
    path = `/meiyun-mct-cms-zhoucun/search-history/delete?ids=${params.ids}`
  }
  return axios.post(path).then((res) => res);
};
// 点赞
export const contentPraise = (params) => {
  axios.defaults.headers['Tenant-Id'] = params.tenantId;
  return axios
    .get(`${base}/meiyun-mct-cms/api/content/praise`, { params: params })
    .then((res) => res);
};
// 收藏
export const contentStar = (params) => {
  axios.defaults.headers['Tenant-Id'] = params.tenantId;
  return axios
    .get(`${base}/meiyun-mct-cms/api/content/star`, { params: params })
    .then((res) => res);
};
// 获取停车场的实时信息
export const getParkingList = (params) => {
  axios.defaults.headers['Tenant-Id'] = params.tenantId;
  return axios
    .get(`${base}/meiyun-mct-cms/parking/list`, { params: params })
    .then((res) => res);
};
